//专家职称   数据库更改 code都为字符, 有文字也有数字
export const professionalTitles = [
  { code: '1', name: '教授' },
  { code: '2', name: '副教授' },
  { code: '3', name: '讲师' },
  { code: '4', name: '助教' },
];

//专家类别
export const expertTypes = [
  { code: 1, name: '硕导' },
  { code: 2, name: '博导' },
  { code: 3, name: '硕博导' },
  { code: 0, name: '无' },
];


export const highestEducationTypes = [
  { code: 1, name: '博士研究生毕业' },
  { code: 2, name: '硕士研究生毕业' },
  { code: 3, name: '大学本科毕业' },
  { code: 4, name: '大学专科毕业' },
  { code: 5, name: '其他' },
];

export const highestDegreeTypes = [
  { code: 1, name: '博士' },
  { code: 2, name: '硕士' },
  { code: 3, name: '学士' },
  { code: 4, name: '其他' },
];


export const professionalTitleLevelTypes = [
  { code: 1, name: '正高级' },
  { code: 2, name: '副高级' },
  { code: 3, name: '中级' },
  { code: 4, name: '初级' },
  { code: 5, name: '无' },
];


