<template>
  <div class="menuBox">
    <div class="userMsg">
      <div class="compontCss userName">
        <div class="name textOneLine">{{ userInfo.name }}</div>
        <div class="daxue textOneLine">{{ userInfo.institutionName }}</div>
        <div class="renzheng">
          <img :src="userInfo.isAuthentication ? authIcons.authenticated : authIcons.unauthenticated" alt="" />
          <p>{{ userInfo.isAuthentication ? '已实名' : '未实名' }}</p>
        </div>
      </div>
    </div>
    <div class="menuList">
      <div v-for="item in menuList" :key="item.id" @click="handleMenuClick(item)"
        @mouseenter="handleMouseEnter(item.id)" @mouseleave="handleMouseLeave"
        :class="['menuItem', { 'active': isMenuActive(item.id) }]">
        <img :src="getMenuIcon(item)" alt="" />
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'MenuComponent',

  data() {
    return {
      authIcons: {
        authenticated: require('../assets/yirenzheng.png'),
        unauthenticated: require('../assets/weirenzheng.png')
      },
      menuList: [
        {
          id: 1,
          name: '首页',
          icon: require('../assets/index.png'),
          iconActive: require('../assets/index.png'),
          path: '/index',
        },
        {
          id: 2,
          name: '接评单',
          icon: require('../assets/jpd.png'),
          iconActive: require('../assets/jpd.png'),
          path: '/jiePingDan',
        },
        {
          id: 3,
          name: '费用结算',
          icon: require('../assets/fyjs.png'),
          iconActive: require('../assets/fyjs.png'),
          path: '/feiYongJieSuan',
        },
        {
          id: 4,
          name: '个人资料',
          icon: require('../assets/grzl.png'),
          iconActive: require('../assets/grzl.png'),
          path: '/geRenZiLiao',
        },
        {
          id: 7,
          name: '研究方向',
          icon: require('../assets/yjfx.png'),
          iconActive: require('../assets/yjfx.png'),
          path: '/yanJiuFangXiang',
        },
        {
          id: 5,
          name: '实名认证',
          icon: require('../assets/zhaq.png'),
          iconActive: require('../assets/zhaq.png'),
          path: '/shimingrenzheng',
        },
        {
          id: 6,
          name: '电子签名',
          icon: require('../assets/grzl.png'),
          iconActive: require('../assets/grzl.png'),
          path: '/dianZiQianMing',
        }
      ],
      activeMenuId: 1,
      hoverMenuId: null,
      isInfoComplete: true
    }
  },

  props: {
    path: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.userMsg
    }),

    isMenuActive() {
      return (menuId) => this.activeMenuId === menuId || this.hoverMenuId === menuId
    },

    getMenuIcon() {
      return (item) => this.isMenuActive(item.id) ? item.iconActive : item.icon
    }
  },

  methods: {

    async handleMenuClick(item) {
      // 如果点击当前页面，不做处理
      if (this.$route.path === item.path) return
      this.activeMenuId = item.id
      this.checkUserInfoComplete().then(res => {
        console.log("res", res);
        // 如果用户信息完整, 则检查研究方向
        if (res) {
          this.checkResearchDirections()
        }
      }).catch(err => {
        console.log("err", err);
      })
      this.$router.push(item.path)
    },

    handleMouseEnter(menuId) {
      this.hoverMenuId = menuId
    },

    handleMouseLeave() {
      this.hoverMenuId = null
    },

    async checkUserInfoComplete() {
      try {
        const res = await request('get', '/personal-information')
        const info = { ...res.data.basicInfo, ...res.data.workInfo }
        const isEnterprise = this.userInfo.institutionType === 1
        console.log("info", JSON.parse(JSON.stringify(info)));
        const isInfoIncomplete = isEnterprise
          ? this.checkEnterpriseInfo(info)
          : this.checkPersonalInfo(info)

        if (!isInfoIncomplete) {
          this.$alert('请完善个人信息', '系统提示', {
            confirmButtonText: '确定',
            callback: () => {
              if (this.$route.path !== '/geRenZiLiao') {
                this.$router.push('/geRenZiLiao')
              }
              this.activeMenuId = 4
            }
          })
        }
        return isInfoIncomplete;
      } catch (error) {
        console.error('检查用户信息失败:', error)
        localStorage.setItem('isInfoIncomplete', false)

      }
    },

    checkEnterpriseInfo(info) {
      const requiredFields = [
        'name', 'company', 'professionalTitle', 'email', 'highestEducation',
        'industry', 'professionalRole', 'professionalTitleLevel', 'highestDegree'
      ]
      // 检查企业信息是否完善
      const missingFields = requiredFields.filter(field => !info[field] || info[field] === '')
      console.log('企业信息缺失字段:', missingFields)
      return missingFields.length === 0
    },

    checkPersonalInfo(info) {
      const requiredFields = [
        'professionalTitle', 'expertType', 'professionalTitleLevel',
        'name', 'email'
      ]
      // 检查个人信息是否完善
      const missingFields = requiredFields.filter(field => !info[field] || info[field] === '')
      console.log('个人信息缺失字段:', missingFields)
      return missingFields.length === 0
    },
    async checkResearchDirections() {
      request('get', '/personal-information/getSubjectAndResearchInterests').then(res => {
        const { professionalDegreeForm = [], subjectForm = [] } = res.data || {};
        const isEnterprise = this.userInfo.institutionType === 1;
        let haveResearch = false;
        if (isEnterprise) {
          haveResearch = !professionalDegreeForm?.[0]?.evaluationPointList?.[0]?.research;
        } else {
          haveResearch = (professionalDegreeForm.length === 0 && subjectForm.length === 0)
        }
        // 如果用户信息完整, 则提示完善研究方向
        if (haveResearch) {
          this.$alert('请完善研究方向', '系统提示', {
            confirmButtonText: '确定',
            callback: () => {
              if (this.$route.path !== '/yanJiuFangXiang') {
                this.$router.push('/yanJiuFangXiang')
              }
              this.activeMenuId = 7
            }
          })
        }

      }).catch(err => {
        console.log("err", err);
      })

    },

    initializeMenu() {
      this.setActiveMenuByPath(this.path)
    },

    setActiveMenuByPath(path) {
      const menuItem = this.menuList.find(item => item.path === path)
      if (menuItem) {
        this.activeMenuId = menuItem.id
      }
    }
  },

  watch: {
    path: {
      handler(newPath) {
        this.setActiveMenuByPath(newPath)
      },
      immediate: true
    }
  },

  created() {
    this.initializeMenu()
  },
  mounted() {

    // 检查用户信息是否完善
    this.checkUserInfoComplete().then(res => {
      console.log("res", res);
      // 如果用户信息完整, 则检查研究方向
      if (res) {
        this.checkResearchDirections()
      }
    }).catch(err => {
      console.log("err", err);
    })
  }
}
</script>

<style lang="scss" scoped>
.menuBox {
  position: fixed;
  top: 50px;
  left: 0;
  width: 208px;

  // border-radius: 10px;
  // margin: auto;
  .userMsg {
    position: relative;
    top: 0;
    width: 90%;
    max-width: 90%;
    height: 100px;
    margin: auto;

    .compontCss {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 70px;
      height: 70px;
    }

    .userIcon {
      border-radius: 50%;

      // background-color: #fff;
      span {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 62px;
        height: 62px;
        line-height: 62px;
        border-radius: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .userName {
      position: relative;
      // top: 40px;
      // margin-left: 10px;
      width: 100%;
      text-align: center;

      .name {
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        color: #212b42;
      }

      .daxue {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: #435c77;
        margin-top: 6px;
      }

      .textOneLine {
        overflow: hidden; //超出部分隐藏
        text-overflow: ellipsis; //超出部分显示...
        white-space: nowrap;
        text-align: center;
        margin-left: -25px;
      }

      .renzheng {
        position: absolute;
        top: 15px;
        right: 0;
        text-align: center;
        font-size: 10px;

        img {
          display: block;
          width: 20px;
          margin: 0 auto;
        }
      }
    }
  }

  .userMsg::after {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    background-color: #fff;
    content: '';
    opacity: 0.2;
  }

  .menuList {
    position: relative;
    top: 0;
    width: 90%;
    max-width: 90%;
    margin: auto;

    .menuItem {
      padding: 10px 0 10px 45px;
      box-sizing: border-box;
      font-size: 14px;
      color: #435c77;
      cursor: pointer;
      margin: 0px 0px 10px 0;

      img {
        position: relative;
        top: -1px;
        width: 14px;
        margin-right: 12px;
      }

      &.active {
        background: #f1f5f7;
        border-radius: 10px;
        color: #435c77;
      }
    }
  }
}
</style>
