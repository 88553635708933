import axios from 'axios';
import Vue from 'vue'
import { Modal } from "ant-design-vue";
import { TrophyTwoTone } from '@ant-design/icons-vue';
import { Message } from 'element-ui';

const envUrls = {
  'yansi.cn': 'https://api.yansi.cn',
  'www.yansi.cn': 'https://api.yansi.cn',
  'pre.yansi.cn': 'https://api-pre.yansi.cn',
  'uat.yansi.cn': 'https://api-uat.yansi.cn',
  'localhost:8081': 'http://localhost:8080'
};
console.log('window.location.host',window.location.host)
const baseUrl = envUrls[window.location.host] || envUrls["pre.yansi.cn"]

let flag = false;

const STATUS_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  SIGNATURE_REQUIRED: 21103,
  SERVER_ERROR: 500,
};

const ENV_CONFIG = {
  PROD: ['yansi.cn', 'www.yansi.cn'],
  PRE: ['pre.yansi.cn'],
  UAT: ['uat.yansi.cn'],
  DEV: ['192.168.23.1', 'localhost:8080']
};

const jumpUrl = (path) => {
  const protocol = ENV_CONFIG.PROD.includes(window.location.host) ||
    ENV_CONFIG.PRE.includes(window.location.host) ||
    ENV_CONFIG.UAT.includes(window.location.host)
    ? 'https'
    : 'http';
  window.location.href = `${protocol}://${window.location.host}/#/${path}`;
}

const request = (method, url, params, headers = {}) => {
  const { isMsg = true } = headers;
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: baseUrl + url,
      headers: {
        'Content-Type': 'application/json;charset:utf-8',
        Authorization: window.localStorage.getItem('token'),
        ...headers
      },
      data: params,
    }).then(res => {
      if (res.status === STATUS_CODES.SUCCESS) {
        const { code, msg } = res.data;
        // console.log('[ code ] >', code)
        switch (code) {
          case STATUS_CODES.UNAUTHORIZED:
            Message.error("登录状态已过期，请重新登录。");
            window.localStorage.clear();
            jumpUrl('login?type=1');
            reject(null);
            break;

          case STATUS_CODES.SIGNATURE_REQUIRED:
            if (!flag) {
              Modal.warning({
                title: "您已评阅的部分论文需要专家签名，研斯数据承诺将对您的签名进行保密，只应用在评阅意见书存档文件中。",
                okText: "设计电子签名",
                keyboard: false,
                onOk() {
                  flag = false;
                  jumpUrl('dianZiQianMing');
                },
              });
              flag = true;
              reject(null);
            }
            break;
          case STATUS_CODES.SUCCESS:
            resolve(res.data);
            break;
          default:
            isMsg && Message.error(msg || "服务器异常!");
            reject(res.data);
        }
      } else {
        Message.error("接口服务异常,请重试");
        reject(null);
      }
    }).catch(error => {
      console.error('Request Error:', error);
      Message.error("网络请求失败，请检查网络连接");
      reject(null);
    });
  });
};

export default request;
